<script lang="ts">
  export let content = '';

  import 'highlight.js/styles/github.css';
  import 'katex/dist/katex.min.css';

  import gfm from '@bytemd/plugin-gfm';
  import highlightSsr from '@bytemd/plugin-highlight-ssr';
  import mathSsr from '@bytemd/plugin-math-ssr';
  import { Viewer } from 'bytemd';
  import { onMount } from 'svelte';
  import { CopyRegular } from 'svelte-awesome-icons';

  const plugins = [gfm(), mathSsr(), highlightSsr()];

  onMount(() => {
    addCopyButtonToCode();
  });

  function addCopyButtonToCode() {
    const subjects = document.querySelectorAll('pre code.hljs');
    const button = createCopyButton();
    subjects.forEach((subject: Element) => {
      let copyButton = subject.nextElementSibling as HTMLButtonElement;

      if (copyButton === null) {
        subject.insertAdjacentElement('afterend', button);

        copyButton = subject.nextElementSibling as HTMLButtonElement;

        if (copyButton) {
          copyButton.addEventListener('click', () => {
            const codeBlock = subject as HTMLElement;
            navigator.clipboard.writeText(codeBlock.textContent ?? '');
          });
        }
      }
    });
  }

  function createCopyButton() {
    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'button-container';
    const newButton = document.createElement('button');
    newButton.className = 'copy-button';
    new CopyRegular({ target: newButton, props: { size: '16', color: 'rgba(0, 0, 0, 0.584)' } });
    newButton.style.position = 'relative';
    buttonContainer.appendChild(newButton);

    return buttonContainer;
  }
</script>

<Viewer value={content} {plugins} />

<style>
  :global(.button-container) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  :global(.copy-button) {
    margin-left: auto;
    padding: 8px;
  }
  :global(.markdown-body) {
    font-size: 1rem;
  }
  @media (prefers-color-scheme: light) {
    :global(.prose :where(code):not(:where([class~='not-prose'], [class~='not-prose'] *))),
    :global(.prose :where(strong):not(:where([class~='not-prose'], [class~='not-prose'] *))) {
      color: black;
    }
    :global(.hljs) {
      background-color: #f0f0f0;
    }
  }
  @media (prefers-color-scheme: dark) {
    :global(.prose :where(code):not(:where([class~='not-prose'], [class~='not-prose'] *))),
    :global(.prose :where(strong):not(:where([class~='not-prose'], [class~='not-prose'] *))) {
      color: white;
    }
    :global(.hljs) {
      background-color: var(--color-ai8-sym-chat-light-gray);
      color: white;
    }
  }
</style>
