<script lang="ts">
  export let documentType: keyof typeof DOCUMENTS_TYPES;
  import { DOCUMENTS_TYPES } from '$lib/utils/constants';
</script>

<div class="flex flex-row mt-14">
  <button
    on:click={() => (documentType = 'generalDocuments')}
    class="mr-10 text-base/[24px] {documentType === DOCUMENTS_TYPES.generalDocuments
      ? 'border-b-2 border-black text-black dark:border-white dark:text-white font-bold'
      : 'text-black dark:text-white'}"
  >
    General Documents
  </button>

  <button
    on:click={() => (documentType = 'feedback')}
    class="text-base/[24px] {documentType === DOCUMENTS_TYPES.feedback
      ? 'border-b-2 border-black text-black dark:border-white dark:text-white font-bold'
      : 'text-black dark:text-white'}"
  >
    Feedback
  </button>
</div>
