<script lang="ts">
  import { getDocumentFromStorage, getStudentGeneralDocuments } from '$lib/api/student';
  import Spinner from '$lib/components/generic/Spinner.svelte';
  import Table from './Table.svelte';
  import type { StudentDocumentResponse } from '$lib/types/response';
  import type { DefaultTableData } from '$lib/types/table';
  import { createEventDispatcher, onMount } from 'svelte';
  import StudentDocumentPreview from './preview/StudentDocumentPreview.svelte';
  const dispatch = createEventDispatcher();
  const studentDocumentConfig: DefaultTableData[] = [
    {
      name: 'name',
      type: 'string',
      label: 'Name',
    },
  ];
  let selected: StudentDocumentResponse | null = null;
  let documents: object[] = [];
  let content: null | string = null;
  let loading = true;
  onMount(async () => {
    await getStudentDocuments();
  });
  async function getStudentDocuments() {
    loading = true;
    let response = await getStudentGeneralDocuments();
    if (response.data && Array.isArray(response.data)) {
      documents = response.data.map((studentDocument: StudentDocumentResponse) => {
        return { ...studentDocument, id: studentDocument.url };
      });
    }
    loading = false;
  }
  async function rowClickHandler(rowClickEvent: CustomEvent) {
    let item = rowClickEvent.detail.item;
    if (item) {
      dispatch('documentPreviewToggled', false);
      let selectedDocument = documents.find((document) => document === item);
      selected = selectedDocument as StudentDocumentResponse;
      loading = true;
      let contentResponse = await getDocumentFromStorage(selected.url);
      content = contentResponse.data;
      loading = false;
    }
  }
  function closeDocumentHandler() {
    selected = null;
    dispatch('documentPreviewToggled', true);
  }
</script>

{#if loading}
  <div class="h-full w-full flex flex-row items-center justify-center dark:bg-ai8-chat-gray">
    <Spinner />
  </div>
{:else if selected === null}
  <Table data={documents} cols={studentDocumentConfig} on:rowClick={rowClickHandler} />
{:else if selected && content}
  <StudentDocumentPreview studentDocument={selected} {content} {loading} on:closeDocument={closeDocumentHandler} />
{/if}
