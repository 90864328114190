<script lang="ts">
  export let loading = true;
  export let name: string;
  export let content: string;
  import ArrowLeft from '$lib/assets/icons/arrow-left-icon.svg';
  import MarkdownPreview from '$lib/components/generic/MarkdownPreview.svelte';
  import Spinner from '$lib/components/generic/Spinner.svelte';
  import { isSmallScreen } from '$lib/utils/window';
  import { createEventDispatcher, onMount } from 'svelte';
  const dispatch = createEventDispatcher();
  let smallScreen = isSmallScreen();
  let isDarkTheme = false;
  onMount(() => {
    isDarkTheme = document.documentElement.classList.contains('dark');
  });
</script>

<div class="flex flex-col bg-white h-[80%] rounded-xl mt-10 px-5 overflow-y-auto dark:bg-gray-800">
  <button class="border-[1px] w-fit rounded-[50%] mt-2" on:click={() => dispatch('closeDocument')}>
    <img src={ArrowLeft} alt="go-back" class="p-3" width="45px" height="50px" />
  </button>
  {#if loading}
    <div class="h-full w-full flex flex-row items-center justify-center dark:bg-ai8-chat-gray">
      <Spinner />
    </div>
  {:else}
    <div class="flex flex-col h-full {smallScreen ? 'pl-5' : 'pl-16'} mt-5">
      <h1 class="text-xl/[35px] font-bold mb-5">
        {name}
      </h1>
      <div class="lg:prose-xl prose-document min-w-full break-all relative" class:prose-document-dark={isDarkTheme}>
        <MarkdownPreview {content} />
      </div>

      <slot name="post" />
    </div>
  {/if}
</div>

<style>
  :global(.prose-document-dark :not(pre code *)) {
    color: rgb(209 213 219 / 1);
  }
  :global(.prose-document-dark pre code.hljs) {
    color: black;
  }
  :global(.prose-document pre code.hljs) {
    background-color: var(--color-ai8-white);
  }
  :global(.prose-document :not(pre) code) {
    background-color: var(--color-ai8-white);
  }
  :global(.prose-document-dark :not(pre) code) {
    background-color: var(--color-ai8-chat-gray);
    color: white;
  }
</style>
