<script lang="ts">
  export let cols: DefaultTableData[];
  export let item: Record<string, any>;

  const dispatch = createEventDispatcher();

  import type { DefaultTableData } from '$lib/types/table';
  import { createEventDispatcher } from 'svelte';
</script>

<li class="min-h-[7vh] border-b-[1px] dark:border-b-neutral-500">
  <button
    class="flex flex-row justify-between items-center w-full h-full hover:bg-ai8-light-gray dark:hover:bg-gray-500"
    on:click={() => dispatch('rowClick', { item: item })}
  >
    {#each cols as col}
      <span class="pl-4 pr-10 text-base/[28px]">
        {item[col.name]}
      </span>
    {/each}
  </button>
</li>
