import { ai8Api, baseApi } from '$src/axios/api';

export const getStudentGeneralDocuments = async () => {
  return ai8Api.get('student-document/general-documents');
};

export const getStudentFeedbackDocuments = async () => {
  return ai8Api.get(`dashboard-quiz-instance/feedbacks`);
};

export const getDocumentFromStorage = async (url: string) => {
  return baseApi.get(url);
};
