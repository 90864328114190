<script lang="ts">
  export let cols: DefaultTableData[];
  export let data: object[];

  import type { DefaultTableData } from '$lib/types/table';
  import { isSmallScreen } from '$lib/utils/window';
  import TableRow from './TableRow.svelte';

  let smallScreen = isSmallScreen();
</script>

<div class="flex flex-col bg-white {smallScreen ? 'h-[70%]' : 'h-[80%]'} rounded-xl mt-10 dark:bg-ai8-sym-chat-gray">
  <ul class="flex flex-col overflow-y-auto h-full">
    <li
      class="min-h-[7vh] flex flex-row items-center text-ai8-dark-gray border-b-[1px] dark:border-b-neutral-500 pl-4 pr-10 w-full justify-between dark:text-white"
    >
      {#each cols as col}
        <span>
          {col.label}
        </span>
      {/each}
    </li>
    {#each data as item}
      <TableRow {item} {cols} on:rowClick />
    {/each}
  </ul>
  <!-- <TablePagination /> -->
</div>
