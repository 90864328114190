<script lang="ts">
  export let loading: boolean;
  export let feedback: FeedbackResponse;
  export let content: string;
  import type { FeedbackResponse } from '$lib/types/response';
  import DocumentPreview from './DocumentPreview.svelte';
</script>

<DocumentPreview name={feedback.quiz_name} {content} {loading} on:closeDocument>
  <div class="mt-auto ml-auto text-2xl pb-8 pr-4" slot="post">
    Grade: {feedback.grade}
  </div>
</DocumentPreview>
