import { browser } from '$app/environment';

/**
 * @deprecated This function is deprecated. Use Tailwind breakpoints instead.
 */
export function isSmallScreen(): boolean {
  if (browser) {
    let zoom = (window.outerWidth - 10) / window.innerWidth;
    let originalClientWidth = document.documentElement.clientWidth * zoom;
    return originalClientWidth < 768;
  }
  return false;
}

export const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;
