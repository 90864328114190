<script lang="ts">
  import SpinnerIcon from '$lib/assets/icons/spinner.svg';
  import { isSmallScreen } from '$lib/utils/window';

  let smallScreen = isSmallScreen();
</script>

<div role="status" class="relative">
  {#if !smallScreen}
    <span class="absolute ml-[25%] mt-[40%] text-2xl/[25px] text-ai8-navy-gray"> Loading... </span>
  {/if}
  <img src={SpinnerIcon} alt="spinner" class="animate-spin {smallScreen ? 'h-16 w-16' : ''}" />
</div>
